<template>
  <div>
    <b-table striped hover sort-icon-right :fixed="isXs"
        class="mb-2 mb-sm-3"
        :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" no-local-sorting @sort-changed="sortChanged"
        v-if="items.length">
      <template #cell(state)="data">
        <span v-b-tooltip="{trigger: istouch()?'click':'hover', delay: 10}" :title="$t('traM.s.'+data.value.toLowerCase())"><Icon :type="data.value" :isButton="false" /></span>
      </template>
      <template #cell(type)="data">
        <span><p :class="getTextFormat(data.item)">{{ getText(data.item) }}</p></span>
      </template>
      <template #cell(amount)="data">
        <span>{{ getMoney(data.item.amount, data.item.currency) }}{{ data.item.tip !== null ? ' + ' + getMoney(data.item.tip, data.item.currency) : '' }}</span>
      </template>
      <template #cell(createdAt)="data">
        <span>{{ getDate(data.value) }}</span>
      </template>
      <template #cell(uuid4)="data">
        <ButtonSet :isDd="isXs" :useCols="false" :suffix="suffix" :id="items.indexOf(data.item)"
            :item="data.item" :showCancel="showCancel" :showReject="showReject" :showSubmit="showSubmit"
            @success="onConfirmation()" :saveResult="true"
            :beforeShow="showShow" beforeVariant="primary" :beforeTo="`/transactions/`+data.item.uuid4" beforeSuffix="Show" beforeIcon="shw">
          <template #before>
            {{ $t('btn.shw') }}
          </template>
        </ButtonSet>
      </template>
    </b-table>
    <b-row fluid v-if="items.length">
      <b-col v-if="showPagination" class="d-flex align-items-end">
        <Pagination ref="pagi" @click="changePage" />
      </b-col>
      <b-col>
        <slot name="nextToPagi" />
      </b-col>
    </b-row>
    <b-card v-if="!items.length">
      <b-link v-if="noHitLink" :to="noHitLink">{{ noHitText }}</b-link>
      <span v-else>{{ noHitText }}</span>
    </b-card>
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { utcToZonedTime } from 'date-fns-tz';
import { REST } from '@/components/RestCall';
import Query from '@/components/RestQuery';
import Pagination from '@/components/Pagination';
import ButtonSet from '@/components/transactions/ButtonSet';

export default {
  data() {
    return {
      sortBy: 'createdAt',
      sortDesc: true,
      // items and pagination would need to be moved to store to survive a "back" from showDetails
      items: [],
    }
  },
  created() {
    // values would need to be moved to store to survive "F5"
    [this.sortDesc, this.sortBy, ] = Query.fetchSort(this.query, this.sortDesc, this.sortBy);
    Query.setSort(this.query, this.sortDesc, this.sortBy, 'uuid4');
    this.getTraData();
  },
  computed: {
    fields() {
      return [
        { key: 'state', label: '', class: 'pl-2 pr-1 pl-sm-2 pr-sm-1 pl-md-3', thStyle: 'width: 25px;' },
        { key: 'type', label: this.$t('tab.v'), class: 'px-1 px-sm-3 text-'+(this.isXs?'truncate':'wrap'), thStyle: 'width: '+ (this.isL?'50':'40') + '%;', sortable: true },
        { key: 'amount', label: this.$t('tab.u'), class: 'px-1 px-sm-3', thStyle: 'width: '+ (this.isL?'25':'30') + '%;', sortable: true },
        { key: 'createdAt', label: this.$t('tab.d'), class: 'px-1 px-sm-3', thStyle: 'width: ' + (this.isL?'25':'30') + '%;', sortable: true },
        { key: 'uuid4', label: this.isXs?'':this.$t('tab.a'), class: 'pl-2 pl-sm-3 pr-0', thStyle: 'width: 35px;' },
      ];
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    uiSett() {
      return this.$store.getters.settings;
    },
    isXs() {
      return this.$store.getters.isXs;
    },
    isL() {
      return this.$store.state.iface.width >= 992;
    },
    tz() {
      return this.$store.state.settings.timezone;
    },
    updTable() {
      return this.$store.state.components.update.traTab;
    },
  },
  watch: {
    updTable() {
      this.getTraData();
    },
    resUuid() {
      this.getTraData();
    },
  },
  methods: {
    getMoney(a, c) {
      if (a == null) return '';
      return this.$n(a/100, { key: 'c', currency: c });
    },
    getText(item) {
      if (item.type === 'PAYMENT') {
        if (this.isUser) {
          return item.receiverName;
        } else {
          return item.ref;
        }
      }
      return this.$t('traM.ty.'+item.type);
    },
    getTextFormat(item) {
      return item.receiverName === null ? 'font-italic' : 'font-weight-normal';
    },
    onConfirmation() {
      // no pagination change necessary: state change does not affect order, delete will auto-update, add does not happen
      this.getTraData(); // update full table, maybe just delete one line better
    },
    sortChanged(ctx) {
      if (ctx.sortBy == '') { return; }
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
      Query.setSort(this.query, ctx.sortDesc, ctx.sortBy, 'uuid4');
      if (this.showPagination) {
        this.$refs.pagi.reset();
      }
      Query.setPagi(this.query, null, this.uiSett.visiblePerPage);
      this.getTraData();
    },
    changePage(nextString) {
      Query.setPagi(this.query, nextString, this.uiSett.visiblePerPage);
      this.getTraData();
    },
    getDate(v) {
      if (!v) return '';
      return this.$d(utcToZonedTime(v+'Z', this.tz), 'as');
    },
    getTraData() {
      let p = null;
      if (this.useResUuid === true) {
        if (this.resUuid == null || this.resUuid != this.$route.params.uuid) return; // protect against shown already while store.oResDetails is still being fetched, any content maybe overwritten!
        p = new Promise(resolve => { resolve(REST.get('/reservations/' + (this.resUuid) + '/transactions')) });
      } else {
        p = new Promise(resolve => { resolve(REST.get('/' + (this.isUser ? 'users' : 'customers') + '/' + this.$store.getters.uuid + '/transactions', this.getFormattedQuery())) });
      }
      p.then(
        // ).catch( // FAKE DATA
        //   () => {
        //     return Promise.resolve({ d: { data: [{
        //       'senderUuid4': '12345678-4444-4444-bbbb-1234567891234',
        //       'receiverUuid4': '4455667788-1234-4321-abcd-123412345555',
        //       'amount': 3599,
        //       'currency': 'EUR',
        //       'type': 'DEPOSIT',
        //       'tax': 1900,
        //       'ref': 'B231-A2',
        //       'uuid4': '33332222-1111-4444-bbbb-aaaabbbbcccc',
        //       'state': 'PREPARED',
        //       'receiverName': null,
        //       'tip': null,
        //       'createdAt': '2021-02-09 16:20',
        //       'data': {}
        //     },{
        //       'senderUuid4': '12345678-4444-4444-bbbb-1234567891234',
        //       'receiverUuid4': '4455667788-1234-4321-abcd-123412345555',
        //       'amount': 3599,
        //       'currency': 'EUR',
        //       'type': 'PAYMENT',
        //       'tax': 1900,
        //       'ref': 'B231-A2',
        //       'uuid4': '33332222-1111-4444-bbbb-aaaabbbbcccc',
        //       'state': 'PREPARED',
        //       'receiverName': 'Full restaurant name',
        //       'tip': null,
        //       'createdAt': '2021-02-09 16:20',
        //       'data': {}
        //     },{
        //       'senderUuid4': '12345678-4444-4444-bbbb-1234567891234',
        //       'receiverUuid4': '4455667788-1234-4321-abcd-123412345555',
        //       'amount': 3599,
        //       'currency': 'EUR',
        //       'type': 'PAYMENT',
        //       'tax': 1900,
        //       'ref': 'B231-A2',
        //       'uuid4': '33332222-1111-4444-bbbb-aaaabbbbcccc',
        //       'state': 'COMPLETED',
        //       'receiverName': 'Full restaurant name2',
        //       'tip': 1000,
        //       'createdAt': '2021-02-09 16:20',
        //       'data': {}
        //     }], links: { next: 'cmVzdEY6Njc1NjIwOTM3MjI2NDMyYmEwYTIzYjE4YTNjOTg5OWU=' }}});
        //   }
        ).then(resp => {
          this.items = resp.d.data;
          if (this.showPagination) {
            this.$nextTick(() => { // refs not yet existing (rendered) without nextTick
              if (resp.d.links.next !== '') {
                this.$refs.pagi.update(resp.d.links.next);
              }
              this.$emit('loaded', { hasContent: true, hasMore: this.$refs.pagi.hasMore() });
            });
          }
        }).catch(e => {
          this.items = [];
          // catch 404 warning: user may not have added favs yet
          if (e.code != 404) {
            this.$store.commit('showWarn',e.message);
          }
          this.$emit('loaded', { hasContent: false, hasMore: false });
        })
    },
    getFormattedQuery() {
      // generate "current" query object, based on supplied template and params
      let newQuery = {...this.query};
      return { params: Query.replaceMagic(newQuery) };
    },
    istouch() {
      return (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
    },
  },
  props: {
    query: {
      type: Object,
      required: true,
    },
    useResUuid: {
      type: Boolean,
      default: false,
    },
    resUuid: {
      type: String,
      default: '',
      required: false,
    },
    noHitText: {
      type: String,
      default: 'No transactions.',
    },
    noHitLink: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      required: true,
    },
    showShow: {
      type: Boolean,
      default: false,
    },
    showReject: {
      type: Boolean,
      default: false,
    },
    showSubmit: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ButtonSet,
    Pagination,
    Icon,
  },
}
</script>
